.title {
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.tags {
    align-items: center;
    column-gap: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0.75rem;
    row-gap: 0.75rem;

    & ~ span {
        background-color: rgba(black, 0.1);
        border-radius: 0.5rem;
        display: block;
        font-weight: 700;
        padding: 0.75rem 0.25rem;
        text-align: center;
    }
}

.check {
    &,
    label,
    input {
        cursor: pointer;
    }
}

.notes {
    margin-top: 1.25rem;
}

.info {
    color: rgba(black, 0.7);
}

.problem {
    align-items: center;
    display: flex;
    margin-top: 0.25rem;

    .input {
        flex: 1;
    }

    .remove {
        margin-left: 1rem;
    }
}

.footer {
    display: flex;
    justify-content: space-between;

    .submit {
        span {
            margin-right: 0.75rem;
        }
    }
}
