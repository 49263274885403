.tableRow {
    font-size: 14px;
}
.helpDate {
    font-size: 11px;
}
.helpPrecedence {
    background-color: #c3c3c3;
    border-radius: 10px;
    color: #fff;
    font-size: 11px;
    padding: 2px 4px;
}