.id {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;

    span {
        font-size: 1.125rem;
        font-weight: 800;
        margin-right: 0.25rem;
    }
}

.status {
    margin-top: 0.375rem;
}
