@import '../../shared/ui/mixins';

.dashboard {
    margin-top: 1rem;

    hr {
        margin: 1rem 0;
    }

    .toggleMap {
        background-color: transparent;
        border-width: 0;
        width: 100%;

        .card {
            align-items: center;
            display: flex;
            flex-direction: row;
            .icon {
                color: #0aa0d2;
                margin-right: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .sessions {
            display: grid;
            gap: 0 1rem;
            grid-template-columns: repeat(2, 1fr);

            &.tomorrow {
                .detail {
                    color: #0aa0d2;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .titleContainer {
            .toggleMap {
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .sessions {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include media-breakpoint-up(xl) {
        .titleContainer {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .toggleMap {
                align-items: center;
                display: flex;
                justify-content: space-between;
                .card {
                    transition: all 150ms ease-in-out;
                    user-select: none;
                    width: auto;

                    &:hover {
                        background-color: #0aa0d2;
                        border-color: #0aa0d2;
                        color: white;
                        cursor: pointer;

                        .icon {
                            color: white;
                            transition: all 150ms ease-in-out;
                        }
                    }
                }
            }
        }

        .sessions {
            gap: 1rem;
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

.name {
    margin: 0;
}

.title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: 1rem;
}

.card {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
    padding: 0.75rem 1rem 1rem;

    .heading {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .scheduled_for {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
        }

        .detail {
            color: #ffd600;
            font-size: 0.75rem;
        }
    }

    .address {
        margin: 0.75rem 0;
        p {
            font-size: 0.875rem;
            margin: 0.25rem 0;
        }
    }

    .responsible {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }

    .product {
        align-items: center;
        display: flex;
        font-size: 0.75rem;
        justify-content: flex-end;
        line-height: 1.25rem;

        .icon {
            margin-left: 0.375rem;
            width: 1rem;

            svg {
                max-height: 2rem;
                max-width: 1rem;
            }
        }
    }

    .actions {
        display: flex;
        margin-top: auto;
        padding-top: 0.75rem;

        button {
            font-size: 0.75rem;

            &:last-child {
                margin-left: auto;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
}

.map {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: all 250ms ease-in-out;
    will-change: transform;

    &.opened {
        transform: translateX(0);
    }

    .close {
        background-color: white;
        border-radius: 0.375rem;
        font-weight: 400;
        position: fixed;
        top: 1rem;
        left: 1rem;

        .icon {
            color: #0aa0d2;
            margin-right: 0.25rem;
        }
    }

    .view {
        height: 100%;
        width: 100%;
    }
}

.actions {
    button:first-child {
        margin-right: 0.5rem;
    }
}

.black {
    .actions {
        button {
            color: #ffd600;
        }
    }
}
