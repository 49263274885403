.wrapper {
    display: flex;
    width: 70vw;
    margin: 24px auto 0;

    gap: 24px;

    .navigator {
        flex: 1;
    }

    .content {
        flex: 2;
    }
}
