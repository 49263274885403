// Buttons
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-border-radius-lg: 10rem;
$btn-border-width: 0.125rem;
$btn-focus-box-shadow: none;

// Container
$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    lg: 1140px,
    xl: 1320px,
    xxl: 1700px
);

// Font
$font-family-sans-serif: 'Inter', sans-serif;

// Footer
$footer-height: 5rem;
$footer-height-lg: 10rem;

// Links
$link-color: #0d6efd;
$link-decoration: none;

// Modal
$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

// Grid
$grid-breakpoints: (
    xxs: 0,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1400px,
    xxl: 1800px
);

$primary: #ffd600;
