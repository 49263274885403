.header {
    align-items: center;
    display: flex;
    height: 4rem;
    justify-content: space-between;

    .nav {
        align-items: center;
        display: flex;
        justify-content: center;

        button {
            align-items: center;
            background-color: transparent;
            border-radius: 1.5rem;
            border-width: 0;
            cursor: pointer;
            display: flex;
            font-size: 1.5rem;
            justify-content: center;
            height: 3rem;
            width: 3rem;
            &:hover {
                background-color: rgba(black, 0.08);
            }
        }

        .date {
            font-size: 1.25rem;
            min-width: 26rem;
            text-align: center;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    strong {
        font-size: 1.75rem;
    }
}

.id {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;

    span {
        font-size: 1.125rem;
        font-weight: 800;
        margin-right: 0.25rem;
    }
}
