@import '../../../shared/ui/mixins';

.main {
    @include media-breakpoint-up(md) {
        display: flex;

        .info {
            align-self: flex-start;
            margin-right: 1.25rem;
            position: sticky;
            top: 1rem;
            width: 22.5rem;
        }
    }

    .info {
        margin-bottom: 1rem;

        .session {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            .image {
                align-items: center;
                background: #e9e9e9;
                border-radius: 6px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                padding: 0.5rem;
                margin: 0.5rem auto;
                height: 4rem;
                width: 8rem;

                img {
                    height: auto;
                    margin: 0 auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .client {
                font-size: 1.25rem;
                margin-bottom: 0.625rem;
                margin-top: 0;
            }

            .id {
                margin: 0 0 0.5rem;
            }
        }

        .scheduled_for {
            font-size: 1.25rem;
            font-weight: 700;
            text-align: center;
            text-transform: capitalize;
            .day,
            .hour {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .address {
            font-size: 1rem;
            margin-top: 1rem;
            text-align: center;

            .area {
                margin-top: 0.75rem;
            }
        }

        .actions {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-top: 0.75rem;

            .button:first-child {
                margin-bottom: 1rem;
                margin-top: 0.25rem;
            }

            .check {
                color: #00b200;
            }

            span {
                margin-left: 0.25rem;
            }
        }
    }

    .wrapper {
        flex: 1;
    }
}
/*.wrapper {
  display: flex;
  flex-direction: column;
}

.line {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;

  @media only screen and (min-width: 1124px) {
    flex-direction: row;
    justify-content: space-around;
  }

  &.between {
    justify-content: space-between;
  }

  &.side {
    justify-content: space-between;
    flex-direction: row !important;
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px 0;
    grid-gap: 15px;

    div,
    img,
    ul,
    li,
    p {
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  &.final {
    margin: 0 1%;
    justify-content: start;
    align-items: center;
  }
}

@mixin card {
  display: flex;
  padding: 20px 25px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .header {
    min-width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;

    &.borderBottom {
      border-bottom: 1px solid #999999;
    }
  }

  .icon > div {
    height: auto;
    margin: 0 15px;
    width: 1.875rem;

    &.noMargin {
      margin: 0 10px 0 0;
    }
    &.marginzero {
      margin: 0;
    }
    &.tiny {
      margin: 0 5px;
      max-width: 12px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    padding: 15px 0;
    box-sizing: border-box;
    border-top: 1px solid #7f7f7f;
    border-bottom: 1px solid #7f7f7f;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;

    .text {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      align-items: center;
      display: flex;
      margin: 0;
      color: #4c4c4c;
      &.big {
        font-size: 26px;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
}

.card {
  display: flex;
  padding: 20px 25px;
  border-radius: 10px;
  background: #fff;
  margin: 0 0 15px;
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;

  &.nopadding {
    max-width: 490px;
    padding: 0;
  }

  &.black {
    border-radius: 10px;
    background: #000;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    color: white;

    p {
      color: white;
    }

    button {
      border-color: white;
      color: white;
      &:hover {
        cursor: pointer;
        background: white;
        color: black;
        border-color: white;
      }
    }
  }

  &.center {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      text-align: center;
    }
  }

  .header {
    min-width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;

    &.borderBottom {
      border-bottom: 1px solid #999999;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    padding: 15px 0;
    box-sizing: border-box;
    border-top: 1px solid #7f7f7f;
    border-bottom: 1px solid #7f7f7f;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 5px 0;
  color: #000;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  align-items: center;
  display: flex;
  margin: 0;
  color: #4c4c4c;
  &.big {
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.itemIcon {
  max-width: 30px;
  width: 100%;
  height: auto;
  margin: 0 15px;
  &.noMargin {
    margin: 0 10px 0 0;
  }
  &.marginzero {
    margin: 0;
  }
  &.tiny {
    margin: 0 5px;
    max-width: 12px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media only screen and (min-width: 720px) {
    grid-template-columns: 1fr 2fr;
  }

  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr 2fr;
  }

  @media only screen and (min-width: 1124px) {
    grid-template-columns: 1fr 3fr;
  }
}

.sideline {
  display: flex;
  flex-direction: column;
}

.sidecolumn {
  display: flex;
  flex-direction: column;
}

.duo {
  div {
    margin: 0 0 15px;
    max-width: 100%;
    // deixa o card full para responsividade

    @media only screen and (min-width: 1124px) {
      margin: 0;

      &:first-child {
        margin: 0 15px 0 0;
      }
    }
  }
}

// MODAL

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

.overlay {
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.modalCard {
  left: 50%;
  top: 50%;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  padding: 30px 20px;
  background: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
}
*/
