$bg-color: white;
$header-padding-x: 1.25rem;

.header {
    background-color: $bg-color;
    box-shadow: 0 0 1rem 0 rgba(black, 0.16);
    padding: 0.75rem $header-padding-x;

    .content {
        display: flex;
        margin: 0 auto;
        max-width: 92%;
        position: relative;
        transition: all 300ms ease-in-out;
        width: 100%;

        @media only screen and (min-width: 540px) {
        }

        @media only screen and (min-width: 720px) {
            width: 94%;
        }

        @media only screen and (min-width: 960px) {
            width: 96%;
        }

        @media only screen and (min-width: 1124px) {
            width: 98%;
            max-width: 1175px;
        }

        .toggle {
            display: none;
        }

        .navbar {
            align-items: center;
            display: flex;
            flex: 1;
            position: relative;

            .nav {
                align-items: center;
                display: flex;
                margin-left: 1.5rem;
                width: 100%;

                .link {
                    color: black;
                    font-weight: 400;
                    margin-right: 0.75rem;
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                    text-decoration: none;

                    &.active {
                        font-weight: 700;
                    }
                }

                .right {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 18px;

                    button,
                    a {
                        color: white;
                    }
                }
            }
        }

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            max-width: 100%;

            .toggle {
                background-color: transparent;
                border: none;
                display: block;
                font-size: 1.5rem;
                margin-left: auto;
            }

            .navbar {
                flex-basis: 100%;

                .nav {
                    align-items: flex-start;
                    background-color: $bg-color;
                    flex-direction: column;
                    justify-content: stretch;
                    margin-left: 0;
                    padding-top: 0.75rem;
                    padding-left: $header-padding-x;
                    padding-right: $header-padding-x;
                    position: absolute;
                    top: 0;
                    left: -$header-padding-x;
                    right: -$header-padding-x;
                    transform: translateY(-200%);
                    transition: all 250ms ease-in-out;
                    width: 100vw;
                    will-change: transform;
                    z-index: 10;

                    .link {
                        display: block;
                        margin-right: 0;
                        padding: 0.75rem 0;
                        width: 100%;
                    }

                    .user {
                        border-top: 1px solid #000000;
                        margin-left: 0;
                        margin-top: 0.5rem;
                        padding: 1rem 0;
                        position: relative;
                        width: 100%;
                    }
                }

                &.opened {
                    .nav {
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}
