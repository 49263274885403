@import '../../../../shared/ui/mixins';

.container {
    @include media-breakpoint-up(lg) {
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: auto 1fr;

        .contact {
            grid-column: 1;
            grid-row: 1 / span 2;
        }

        .responsible {
            grid-column: 2;
            grid-row: 1;
        }

        .extra {
            grid-row: 2;
            grid-column: 2;
        }
    }
}

.card {
    margin-bottom: 1rem;

    .title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.125rem;
        margin-top: 0;
    }

    .name {
        font-size: 1rem;
        margin-bottom: 0.125rem;
        margin-top: 0;
    }
}

.contact {
    .name {
        text-align: center;
    }
    .button {
        font-size: 0.9375rem;
        margin-top: 1.625rem;
        text-align: center;
        width: 100%;

        span {
            margin-left: 0.25rem;
        }
    }

    .whatsAppMessage {
        color: #7f7f7f;
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-top: 0.5rem;
        text-align: center;
    }
}

.responsible {
    .info {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        white-space: pre-line;
        span {
            margin-left: 0.25rem;
        }
    }
}

.schedulingResponsible .body {
    align-items: center;
    display: grid;
    gap: 0.25rem 0.75rem;
    grid-template-columns: auto 1fr;
    .title {
        grid-column: 1 / span 2;
    }
}

.extra {
    .deliveryLink {
        align-items: center;
        column-gap: 0.25rem;
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        width: 100%;

        .label {
            white-space: nowrap;
        }

        .link {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
