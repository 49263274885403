.backButton {
    height: 76px;
    width: 100%;
    display: flex;
    align-items: center;
}

.errors{
    color: #B23128;
}

.p8 {
    padding: 8px;
}

.h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    color: #333333;
    //margin-bottom: 32px;
}

.my16 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.date {
    margin-top: 24px;
    margin-bottom: 24px;
}

.centerAll {
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type='time'] {
    border-radius: 4px;
    padding: 6px;
    width: 173px;
    border: 1px #ccc solid;
}

.mr4 {
    margin-right: 4px;
}
.ml4 {
    margin-left: 4px;
}

.select {
    appearance: none;
    background-color: transparent;
    border: 1px #ccc solid;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
}

@media (min-width: 700px) {
    .flex {
        display: flex;
    }

    .date {
        max-width: 350px;
        margin-right: 1rem;
    }

    .container {
        max-width: 720px;
        margin: auto;
    }
}
