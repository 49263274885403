.wrapper .content {
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    padding: 0 0.75rem;
    transition: all 300ms ease-in-out;
    width: 100%;

    // @media only screen and (min-width: 540px) {
    // }

    @media only screen and (min-width: 720px) {
        padding: 0;
        max-width: 92%;
    }

    @media only screen and (min-width: 960px) {
        max-width: 94%;
    }

    @media only screen and (min-width: 1124px) {
        max-width: 96%;
    }
}
