@use 'sass:math';
@import '../../../shared/ui/mixins';

.navigation {
    align-items: center;
    background-color: rgba(black, 0.8);
    display: flex;
    padding-bottom: max(env(safe-area-inset-bottom), 1rem);
    padding-left: max(env(safe-area-inset-left), 1rem);
    padding-right: max(env(safe-area-inset-right), 1rem);
    padding-top: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .btn {
        align-items: center;
        background-color: rgba(white, 0.7);
        border-radius: 1rem;
        border-width: 0;
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        min-height: 2rem;
        min-width: 2rem;
        transition: opacity 200ms linear;

        &:first-child:disabled {
            opacity: 0;
        }
    }

    .sessions {
        flex: 1;
        height: 3.75rem;
        position: relative;
        overflow: hidden;

        .list {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            overflow-x: visible;
            transition: transform 300ms linear;
            will-change: transform;

            .session {
                align-items: center;
                display: flex;
                flex-basis: 100%;
                flex-direction: column;
                flex-shrink: 0;
                justify-content: center;
                min-width: 100%;
                position: relative;
                transition: transform 200ms linear;
                will-change: transform;
                .date {
                    color: white;
                    font-size: 0.6875rem;
                    line-height: 0.6875rem;
                    padding-bottom: 0.375rem;
                }
                .button {
                    padding: 0.0625rem 0.75rem;
                    .id {
                        font-weight: 700;
                    }
                    .address {
                        font-size: 0.6875rem;
                        font-weight: 200;
                        letter-spacing: math.div(-1, 100)rem;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}