.footer {
    box-sizing: border-box;  
    padding: 0.75rem 0;
    width: 100%;

    .text {
        color: #7f7f7f;
        font-size: 0.875rem;
        font-weight: normal;
        text-align: left;
    }
}
