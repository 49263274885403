@import '../../../shared/ui/mixins';

.body {
    padding: 0.5rem;
    min-height: 20rem;

    .header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .nav {
            display: flex;
            justify-content: space-around;
            flex-basis: 100%;
            margin-bottom: 0.625rem;

            .link {
                background-color: transparent;
                border-width: 0;
                border-bottom-color: transparent;
                border-bottom-style: solid;
                border-bottom-width: 0.25rem;
                color: #000;
                display: inline-block;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.25rem;
                padding: 0.375rem 0.375rem;
                position: relative;
                text-align: center;

                &.active {
                    border-bottom-color: #ffd600;
                }
            }
        }

        button {
            font-size: 0.75rem;
            font-weight: 400;
            padding: 0.25rem 0.375rem;
            letter-spacing: -0.0125rem;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 1rem;

        .header {
            flex-wrap: nowrap;

            .nav {
                flex-basis: auto;
                
                .link {
                    font-size: 1rem;
                    margin-left: 0.25rem;
                    padding: 0.375rem 0.75rem;
                }
            }

            button {
                font-size: 0.875rem;
                font-weight: 600;
                padding: 0.5rem 0.875rem;
                letter-spacing: 0;
            }
        }
    }
}

.id {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;

    span {
        font-size: 1.125rem;
        font-weight: 800;
        margin-right: 0.25rem;
    }
}
