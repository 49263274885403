.container {
    background-color: #ffd600;
    box-sizing: border-box;
    display: flex;
    padding: 0.625rem;
    height: 100vh;
    width: 100vw;

    .wrapper {
        background-color: white;
        border-radius: 3.125rem;
        box-sizing: border-box;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        grid-template-columns: 1fr 1fr;
        margin: auto;
        max-width: unquote('min(57rem, 90vw)');
        padding: 5vh 1rem;
    }

    .logo,
    .form {
        flex: 1;
        min-width: unquote('min(26rem, 100%)');
    }

    .logo {
        align-items: center;
        display: flex;
        justify-content: center;

        img {
            width: 25%;
        }
    }

    .form {
        box-sizing: border-box;

        .title {
            font-size: 1.5rem;
            text-align: center;
        }

        .content {
            box-sizing: border-box;
            padding: 0.5rem;
        }

        .input {
            background-color: #e5e5e5;
            border: 0.125rem solid #e5e5e5;
            border-radius: 1.75rem;
            box-sizing: border-box;
            display: flex;
            height: 2.5rem;
            margin-bottom: 0.75rem;
            padding: 0.25rem 0.5rem;
            transition: border-color 200ms ease-in-out;

            &:focus-within {
                border-color: #aaa;
            }

            .icon {
                align-self: center;
                color: rgba(black, 0.5);
                text-align: center;
                width: 1.75rem;
            }

            input {
                align-self: stretch;
                background-color: transparent;
                border-width: 0;
                flex: 1;
                font-size: 0.875rem;

                &:focus {
                    outline: none;
                }
            }
        }

        .action {
            text-align: right;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                margin-left: 3px;
                color: black;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .logo {
            box-sizing: border-box;
            margin-right: 1.5rem;

            img {
                width: 60%;
            }
        }

        .form {
            box-sizing: border-box;
            margin-left: 1.5rem;
            padding: 1rem;

            .title {
                font-size: 2rem;
                text-align: left;
            }

            .input {
                height: 3.5rem;
                padding: 0.5rem 1rem;

                .icon {
                    font-size: 1.25rem;
                    width: 2.5rem;
                }

                input {
                    font-size: 1.125rem;
                }
            }
        }
    }
}
