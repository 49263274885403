@import 'bootstrap';
@import '~react-quill/dist/quill.snow.css';

/* react-datepicker */
$datepicker__font-family: 'Inter';
$datepicker__selected-color: #ffd600;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker-wrapper {
    width: 100%;
}

div.react-datepicker-popper {
    z-index: 1090;
}

.react-datepicker {
    border-radius: 0.75rem;

    .react-datepicker__navigation {
        &.react-datepicker__navigation--previous {
        }
        .react-datepicker__navigation--next {
        }
    }

    .react-datepicker__header {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;

        .react-datepicker__current-month {
            text-transform: capitalize;
        }
    }

    .react-datepicker__month-container {
        .react-datepicker__day {
            border-radius: 0.85rem;

            &--selected,
            &--in-selecting-range {
                color: black;
            }

            &--selected,
            &--keyboard-selected {
                color: black;
            }
        }
    }
}

/* google autocomplete */
div.pac-container {
    z-index: 1100;
}
