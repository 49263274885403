.card {
    min-height: 240px;
}

.form {
    div {
        margin-bottom: 0;
    }
    * + * {
        margin-top: 24px;
        margin-bottom: 0;
    }
}
