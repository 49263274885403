.header {
    align-items: center;
    display: flex;
    justify-content: center;

    button {
        align-items: center;
        background-color: transparent;
        border-radius: 1.5rem;
        border-width: 0;
        cursor: pointer;
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        &:hover {
            background-color: rgba(black, 0.08);
        }
    }

    .date {
        font-size: 1.25rem;
        min-width: 26rem;
        text-align: center;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
