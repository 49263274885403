// @import '../../../../shared/ui/mixins';

.heading {
    font-weight: 700;
    text-align: center;
}

.title {
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.info {
    margin-bottom: 0.125rem;
}

// .report {
//     @include media-breakpoint-up(md) {
//         display: grid;
//         grid-auto-columns: 1fr;
//         grid-auto-flow: column;
//     }
// }
